import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
const env_datas = environment.firebase;
const CALL_API = env_datas.api_url;
@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private http: HttpClient) {}
  getDashboardData() {
    return this.http.get(`${CALL_API}dashboard`);
  }
  getDashboardSeaarchData() {
    return this.http.get(`${CALL_API}dashboardata`);
  }
  filterSearch(data) {
    return this.http.post(`${CALL_API}searchFilter`, data);
  }
  filterSearch2(data) {
    return this.http.post(`${CALL_API}searchFilter2`, data);
  }
  updateDefault(data) {
    return this.http.post(`${CALL_API}updateDefault`, data);
  }
  getvinRego(bkNumber) {
    return this.http.get(`${CALL_API}getvinRego/` + bkNumber);
  }

  getSeaVessalAllocationData(){
    return this.http.get(`${CALL_API}vessel-allocation-data`);
  }

  getInactiveBookingsData(){
    return this.http.get(`${CALL_API}inactive-bookings`);
  }

  getOtherVoyagesData(){
    return this.http.get(`${CALL_API}other-voyages-data`);
  }

  getRoadServicesData(){
    return this.http.get(`${CALL_API}road-services-data`);
  }

  getRemindersData(){
    return this.http.get(`${CALL_API}reminders-data`);
  }
  filterSearch1(data) {
    return this.http.post(`${CALL_API}searchFilterApi`, data);
  }
}
