import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
// import {  Observable, throwError } from 'rxjs';
// import { catchError } from 'rxjs/operators';
const env_datas = environment.firebase;
const CALL_API = env_datas.api_url;
@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  // httpOptions = {
  //   headers: new HttpHeaders({
  //     'Content-Type': 'application/json'
  //   })
  // }

  constructor(private http:HttpClient) { }
  // errorHandler(error:any) {
  //   let errorMessage = '';
  //   if(error.error instanceof ErrorEvent) {
  //     errorMessage = error.error.message;
  //   } else {
  //     errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
  //   }
  //   return throwError(errorMessage);
  // }
  // getAll() {

  //   return this.http.get(`${CALL_API}ports`)

  //   .pipe(
  //     catchError(this.errorHandler)
  //   )
  // }
  //ports

  getPorts() {    
    return this.http.get(`${CALL_API}ports`);
  }
  addPort(data){
    return this.http.post(`${CALL_API}ports`,data);
  }
  updatePort(id,data){
    return this.http.put(`${CALL_API}ports/`+id,data);
  }
  changestatus(data){
    return this.http.post(`${CALL_API}portStatus`,data);
  }
  deleteport(data){
    return this.http.post(`${CALL_API}portDelete`,data);
  }

 // cargo
  getCargo() {    
    return this.http.get(`${CALL_API}cargo`);
  }
  shippinglineDropdownValue(){
    return this.http.get(`${CALL_API}slDropDown`);
  }
  cargoAdd(myFormData){
    return this.http.post(`${CALL_API}cargo`,myFormData);
  }
  getCargoDetails(id){
    return this.http.get(`${CALL_API}cargodetails/`+id);
  }
  changeCargostatus(data){
    return this.http.post(`${CALL_API}cargoStatus`,data);
  }
  cargoExcport(myFormData:FormData){
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');     
    return this.http.post(`${CALL_API}cargoExport`,myFormData,{headers:headers,responseType:'arraybuffer'});
  }
  uploadCargoexcel(myFormData:FormData){
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');   
    return this.http.post(`${CALL_API}cargoDetailImport`,myFormData,{headers:headers});
  }

  addCargo(data){
    return this.http.post(`${CALL_API}add-cargo`,data);
  }
  updateCargo(data){
    return this.http.post(`${CALL_API}update-cargo`,data);
  }

  // cargo type
  getCargotypes(){
    return this.http.get(`${CALL_API}cargotype`);
  }
  addCargotype(data){
    return this.http.post(`${CALL_API}cargotype`,data);
  }
  updateCargotype(id,data){
    return this.http.put(`${CALL_API}cargotype/`+id,data);
  }
  cargotypechangestatus(data){
    return this.http.post(`${CALL_API}cargotypeStatus`,data);
  }
  deletecargotype(data){
    return this.http.post(`${CALL_API}cargotypeDelete`,data);
  }

  // shippingLine
  getShippinglines(){
    return this.http.get(`${CALL_API}shippingline`);
  }
  addShiipingline(data){
    return this.http.post(`${CALL_API}shippingline`,data);
  }
  updateShiipingline(id,data){
    return this.http.put(`${CALL_API}shippingline/`+id,data);
  }
  slchangestatus(data){
    return this.http.post(`${CALL_API}shippingLineStatus`,data);
  }
  deletesl(data){
    return this.http.post(`${CALL_API}shippingLineDelete`,data);
  }

  /* frequent transport */

  getFrequentTransports(){
    return this.http.get(`${CALL_API}frequenttransport`);
  }

  deleteFrequentTransports(id){
    return this.http.delete(`${CALL_API}frequenttransport/`+id);
  }
  FrequentDownload(id){
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');     
    return this.http.get(`${CALL_API}downloadFrequentTransport/${id}`,{headers:headers,responseType:'arraybuffer'});
  }
  uploadFrequent(myFormData:FormData){
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');   
    return this.http.post(`${CALL_API}uploadFrequentTransport`,myFormData,{headers:headers});
  }
  updateFrequentTransport(data,id){
    return this.http.post(`${CALL_API}frequenttransport/${id}`,data);

  }
  addFrequentTransport(data){
    return this.http.post(`${CALL_API}frequenttransport`,data);

  }

  /* billing code */

  getListBillingCode(){
    return this.http.get(`${CALL_API}billingcode`);
  }

  PostBilling(data){
    return this.http.post(`${CALL_API}billingcode`,data);
  }

  UpdateBilling(id,data){
    return this.http.put(`${CALL_API}billingcode/`+id,data);
  }
  billingStatus(id){
    return this.http.get(`${CALL_API}billingCodeStatus/`+id);
  }

  deleteBilling(id){
    return this.http.delete(`${CALL_API}billingcode/`+id);
  }
  getVendors() {    
    return this.http.get(`${CALL_API}gVendors`);
  }
}
