import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
const env_datas = environment.firebase;
const CALL_API = env_datas.api_url;

@Injectable({
  providedIn: 'root'
})
export class DeliveryService {

  constructor(private http:HttpClient) { }

  addDeliveryVendor(data:any){
    return this.http.post(`${CALL_API}addDeliveryVendor`,data);
  }
  getDeliveryVendor(id){
    return this.http.get(`${CALL_API}getDeliveryVendor/`+id);
  }
  getAllDeliveryVendors(){
    return this.http.get(`${CALL_API}getAllDeliveryVendors`);
  }
  changeStatus(data){
    return this.http.post(`${CALL_API}deliveryStatusChange`,data);
  }
  deleteDelivery(data){
    return this.http.post(`${CALL_API}deleteDelivery`,data);
  }


  
  addCollectionPoint(data:any){
    return this.http.post(`${CALL_API}addCollectionPoint`,data);
  }
  getCollectionPoint(id){
    return this.http.get(`${CALL_API}getCollectionPoint/`+id);
  }
  getAllCollectionPoints(){
    return this.http.get(`${CALL_API}getAllCollectionPoints`);
  }
  collectionChangeStatus(data){
    return this.http.post(`${CALL_API}collectionStatusChange`,data);
  }
  collectionDelete(data){
    return this.http.post(`${CALL_API}collectionDelete`,data);
  }
  
}
