import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient,HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
const apiurl = environment.firebase.api_url;

const env_datas = environment.firebase;
const CALL_API = env_datas.api_url;
@Injectable({
  providedIn: 'root'
})
export class VendorService {

  constructor(private http:HttpClient,private httpClientdirecr: HttpClient,private handler: HttpBackend) {
    this.httpClientdirecr = new HttpClient(handler);
   }
  addVendor(data:any){
    return this.http.post(`${CALL_API}vendors`,data);
  }
  updateVendor(data:any,id){
    return this.http.put(`${CALL_API}vendors`+'/'+id,data);
  }
  getVendor(id){
    return this.http.get(`${CALL_API}gVendor/`+id);  
  }
  addBranch(data:any){
    return this.http.post(`${CALL_API}aVBranch`,data);
  }
  updateBranch(data:any){
    return this.http.post(`${CALL_API}uVBranch`,data);
  }
  getBranches(accId){
    return this.http.get(`${CALL_API}gVBranch/`+accId);
  }

  addContact(data:any){
    return this.http.post(`${CALL_API}aVContact`,data);
  }
  updateContact(data:any){
    return this.http.post(`${CALL_API}uVContact`,data);
  }
  getContacts(accId){
    return this.http.get(`${CALL_API}gVContact/`+accId);
  }

  addDocument(myFormData:FormData){
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json'); 
    return this.http.post(`${CALL_API}aVDocument`,myFormData,{headers:headers});
  }
  updateDocument(myFormData:FormData){
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json'); 
    return this.http.post(`${CALL_API}uVDocument`,myFormData,{headers:headers});
  }
  getDocuments(accId){
    return this.http.get(`${CALL_API}gVDocs/`+accId);
  }
  deleteRow(data){
    return this.http.post(`${CALL_API}dVDocs`,data);
  }

  getVendors(){
    return this.http.get(`${CALL_API}vendors`);
  }
  changeStatus(data){
    return this.http.post(`${CALL_API}sVendor`,data);
  }
  deleteAccRow(data){
    return this.http.post(`${CALL_API}dVendor`,data);
  }
  getDeliveryOnloadData(){
    return this.http.get(`${CALL_API}getDeliveryOnloadData`);
  }
  getDeliveryprefill(id){
    return this.http.get(`${CALL_API}getDeliveryprefill/`+id);
  }
  getBookingList(data){
    return this.http.post(`${CALL_API}getBookingList`,data); 
  }
  conditionCheck(data){
    return this.http.post(`${CALL_API}conditionCheck`,data); 
  }
  getBookingDestination(data){
    return this.http.post(`${CALL_API}getBookingDestination`,data);
  }
  sumbitDelivery(data){
    return this.http.post(`${CALL_API}sumbitDelivery`,data);
  }
  getDBookingList(){
    return this.http.get(`${CALL_API}getDBookingList`);
  }
  fetchData(id){
    return this.http.get(`${CALL_API}fetchData/`+id);
  }
  sandBoxApiCreate(data){
    return this.http.post(`${CALL_API}createSandBoxBooking`,data); 
  }
  sandBoxApiServ(data):Observable<any>{
    // const headers = new HttpHeaders();
    const token = 'a1582ac2d99c17ce32080d8825a2bf79bd2b0a9372c925a01747f984942bc190';
    const headers = new HttpHeaders({
      // 'Content-Type': 'application/json',
    // 'Access-Control-Allow-Origin': '*',
    // 'Access-Control-Allow-Origin': '*',
      // 'Access-Control-Allow-Headers': 'Content-Type',
    // 'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
    // 'Access-Control-Allow-Credentials':'true',
    // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, X-WP-Nonce, Content-Type, Accept, Authorization',
      // 'Authorization': 'Bearer a1582ac2d99c17ce32080d8825a2bf79bd2b0a9372c925a01747f984942bc190',
    // 'Access-Control-Request-Method': 'POST',
    'Content-Type': 'application/json',    
    'Accept': '*',
    // 'Authorization': `Bearer ${token}`
    })
    // headers.append('Content-Type', 'multipart/form-data');
    // headers.append('Accept', 'application/json');   
    // const token = 'a1582ac2d99c17ce32080d8825a2bf79bd2b0a9372c925a01747f984942bc190';
    // headers.append("Authorization", "Bearer " + token);
    // var body = JSON.stringify(data);
    let data1 = {
      test:1
    }
    return this.http.post(`https://api.sandbox.gotoblink.com/job/booking/create`,data1,{headers:headers})
    //  .pipe(
    //   map((res: any) => res),
    //   catchError(this.handleError)
    // );
  }
  saveIntegrationData(data){
    return this.http.post(`${CALL_API}saveIntegrationData`,data);
  }
  getbookingDetails(id){
    return this.http.get(`${CALL_API}getbookingDetails/`+id);
  }
  getBlinkData(id){
    const token = 'a1582ac2d99c17ce32080d8825a2bf79bd2b0a9372c925a01747f984942bc190';
    const headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data',
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`
    })
    return this.httpClientdirecr.get(`https://api.sandbox.gotoblink.com/job/booking/read?id=`+id,{headers:headers});
  }
  getCargos(id){

  }
  //ss_c_28072023-export vendor excel
  exportVendorExcel(data: any) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.post<any>(`${apiurl}vendor-contact-export`, data, {
      headers: headers,
      responseType: 'arraybuffer' as 'json'
    })
      .pipe(map((res: any) => {
        return res;
      }))
  }

  uploadExcel(data: any) {
    return this.http.post<any>(`${apiurl}vendor-excel-import`, data);
  }

  vendorDownload(){
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');     
    return this.http.get(`${CALL_API}downloadVendorContact`,{headers:headers,responseType:'arraybuffer'});
  }
}
